import React from "react";
import "./charts.css"; // Ensure to create and link the corresponding CSS file
import TradingViewWidgetBTC from "./TradingViewWidgetBTC";
import TradingViewWidgetETH from "./TradingViewWidgetETH";
import TradingViewWidgetSOL from "./TradingViewWidgetSOL";

function Charts() {
  return (
    <div className="charts-container">
      <div className="chart-box">
        <div className="box-header">BTCUSD</div>
        <div className="chart-content">
          <TradingViewWidgetBTC />
        </div>
      </div>
      <div className="chart-box">
        <div className="box-header">ETHUSD</div>
        <div className="chart-content">
          <TradingViewWidgetETH />
        </div>
      </div>
      <div className="chart-box">
        <div className="box-header">SOLUSD</div>
        <div className="chart-content">
          <TradingViewWidgetSOL />
        </div>
      </div>
    </div>
  );
}

export default Charts;
