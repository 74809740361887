import React from "react";
import "./DescriptionTab.css"; // Make sure you link to your CSS file for this component

class DescriptionTab extends React.Component {
  render() {
    return (
      <div className="description-tab">
        <div className="tab-content">
          <div className="company-profile">
            <h1>BT247</h1>
            <p>BT247/ETH</p>
            <p>Contract Address: 0xd45da6cc4e24918611cfd12a83586aed5c12cbc5</p>
            <p>
              BLOOMBERGTERMINAL247 [BT247] aggregates, publishes and distributes
              cryptocurrency related financial news. All news provided by
              BT247 is almost entirely generated by the greatest minds in
              the financial world, including GPT-4o and GROK.
            </p>
          </div>

          <div className="additional-info">
            <div className="info-row">
              <div className="info-box">
                <div className="box-header">Token Info</div>
                <div className="box-content">
                  <p>Ticker: $BT247</p>
                  <p>Total Supply: 1,000,000,000</p>
                  <p>LP: 100%</p>
                </div>
              </div>
              <div className="info-box">
                <div className="box-header">Contract Info</div>
                <div className="box-content">
                  <p>
                    LP: <span class="green">Burned</span>
                  </p>
                  <p>
                    Contract: <span class="green">Revoked</span>
                  </p>
                  <p>
                    Taxes: <span class="green">None</span>
                  </p>
                </div>
              </div>
              <div className="info-box">
                <div className="box-header">Socials</div>
                <div className="box-content">
                  <p>
                    Twitter: <a href="https://x.com/BT247">@BT247COIN</a>
                  </p>
                  <p>
                    Telegram: <a href="https://t.me/BT247">t.me/BT247</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DescriptionTab;
