import React, { useState } from "react";
import "./HowToBuy.css"; // Make sure this CSS file is created and linked for styling

const stepsContent = [
  {
    id: "step1",
    title: "Step 1",
    content: (
      <div>
        Navigate to the <strong>'Settings'</strong> option within your wallet
        and locate the networks section. Manually include a new network, and
        input the following details for Base:
        <ul>
          <li>Network Name: Base</li>
          <li>
            RPC Endpoint:{" "}
            <a
              href="https://mainnet.base.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://mainnet.base.org/
            </a>
          </li>
          <li>Chain ID: 8453</li>
          <li>Currency Symbol: ETH</li>
          <li>
            Block Explorer:{" "}
            <a
              href="https://basescan.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://basescan.org
            </a>
          </li>
        </ul>
        Click on <strong>'Save'</strong> and then{" "}
        <strong>'CONNECT TO BASE'</strong> after saving the information. Upon
        completion, you can connect to Base by choosing it from the network
        selection menu.
      </div>
    ),
  },
  {
    id: "step2",
    title: "Step 2",
    content: (
      <div>
        To fund your Base wallet with ETH, you have a couple of options:
        <ol>
          <li>
            Transfer ETH from Coinbase, Binance, Byit, or OKX to your Base
            wallet. Ensure you select Base as the withdrawal network.
          </li>
          <li>
            Utilize the following bridges to transfer ETH to Base from another
            chain:
            <ul>
              <li>
                Official Base Bridge:{" "}
                <a
                  href="https://bridge.base.org/deposit"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://bridge.base.org/deposit
                </a>
              </li>
              <li>
                Orbiter:{" "}
                <a
                  href="https://www.orbiter.finance/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.orbiter.finance/
                </a>
              </li>
              <li>
                Stargate:{" "}
                <a
                  href="https://stargate.finance/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://stargate.finance/
                </a>
              </li>
            </ul>
          </li>
        </ol>
        To employ these bridges, connect your wallet, choose the network you
        wish to bridge from, designate Base as the target network, and specify
        the amount of ETH you intend to transfer.
      </div>
    ),
  },
  {
    id: "step3",
    title: "Step 3",
    content: (
      <div>
        <ol>
          <li>
            Change the network in your wallet to Base from the network dropdown
            menu.
          </li>
          <li>
            Visit{" "}
            <a
              href="https://app.uniswap.org/?chain=base"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.sushi.com/swap
            </a>
            .
          </li>
          <li>Link your wallet.</li>
          <li>
            In the lower swap field, choose a token and enter the official $FNCS
            token address:{" "}
            <strong>0xd45da6cc4e24918611cfd12a83586aed5c12cbc5</strong>.
          </li>
        </ol>
      </div>
    ),
  },
  {
    id: "step4",
    title: "Step 4",
    content: (
      <div>
        Execute the swap by following these steps:
        <ol>
          <li>Input the desired amount of ETH.</li>
          <li>
            Adjust the slippage to 3-4% by clicking on the settings wheel.
          </li>
          <li>Click on "Swap."</li>
          <li>Confirm the transaction within your wallet.</li>
        </ol>
      </div>
    ),
  },
];

function HowToBuy() {
  const [activeTab, setActiveTab] = useState(stepsContent[0].id);

  return (
    <div className="how-to-buy-container">


      <div className="tab-header">
        {stepsContent.map((step) => (
          <button
            key={step.id}
            className={`tab-button ${activeTab === step.id ? "active" : ""}`}
            onClick={() => setActiveTab(step.id)}
          >
            {step.title}
          </button>
        ))}
      </div>
      <div className="tab-content">
        {stepsContent.map((step) => (
          <div
            key={step.id}
            className={`content ${activeTab === step.id ? "active" : "hidden"}`}
          >
            <p>{step.content}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default HowToBuy;
