const MobileLayout = {
  global: { tabEnableClose: false },
  borders: [],
  layout: {
      type: "row",
      id: "#1",
      weight: 100,
      children: [
          {
              type: "row",
              id: "#16",
              weight: 100,
              children: [
                  {
                      type: "tabset",
                      id: "#3",
                      weight: 20.509011685482275,
                      children: [
                          {
                              type: "tab",
                              name: "News",
                              id: "#4",
                              component: "news",
                          },
                          {
                              type: "tab",
                              name: "Charts",
                              id: "#12",
                              component: "charts",
                          },
                      ],
                  },
                  {
                      type: "tabset",
                      id: "#5",
                      weight: 31.822806390835126,
                      children: [
                          {
                              type: "tab",
                              name: "$FNCS Summary",
                              id: "#6",
                              component: "DescriptionTab",
                          },
                          {
                              type: "tab",
                              name: "How To Buy",
                              id: "#11",
                              component: "HowToBuy",
                          },
                      ],
                  },
                  {
                      type: "tabset",
                      id: "#8",
                      weight: 18.50523291340534,
                      children: [
                          {
                              type: "tab",
                              name: "$FNCS News",
                              id: "#9",
                              component: "ExpandedStory",
                              config: {
                                title:
                                  "Financ.es/FinancesAI Launch: Investors Excited About the Future of Base L2",
                                timestamp: "07:45 AM",
                                content: `In a groundbreaking development in the world of fintech, the highly anticipated launch of Financ.es teamed with FinanceAI has set the financial communities abuzz with excitement.

              Leveraging the innovative Base L2 technology, FinanceAI promises to revolutionize the way we think about and interact with financial markets. With its advanced analytics and machine learning capabilities, FinanceAI aims to provide unparalleled insights into market trends and investment opportunities.

              Investors have been eagerly awaiting the chance to get involved with Financ.es/FinanceAI, and with the launch, there's a palpable sense of enthusiasm for what's being touted as a giant leap forward for financial technologies. "It's not just about the technology; it's about the potential to transform our entire approach to financial decision-making," says one industry expert.

              As the platform goes live, the focus now turns to the user experience and the real-world benefits that FinanceAI will deliver. The financial world is watching with bated breath, ready to dive into the future of fintech powered by Base L2.`,
                              },
                          },
                      ],
                  },
              ],
          },
      ],
  },
};

export default MobileLayout;
