import React from "react";
import "./ExpandedStory.css"; // Ensure to create and link the corresponding CSS file

class ExpandedStory extends React.Component {
  render() {
    const { title, timestamp, content } = this.props.story; // Destructure the story details passed as props
    return (
      <div className="expanded-story-container">

        <div className="story-header">
          <h1 className="story-title">{title}</h1>
          <div className="story-timestamp">{timestamp}</div>
        </div>
        <div className="story-content">
          <p>{content}</p>
        </div>
      </div>
    );
  }
}

export default ExpandedStory;
